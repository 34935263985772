import React from "react"
import * as css from "@css/atoms/a-section.module.styl"

function Section({ children })
{
    return (
        <section className={css.aSection}>
            <div className={css.aSection__inner}>
                {children}
            </div>
        </section>
    )
}

function SectionHeader({ children, title })
{
    return (
        <header className={css.aSection__header}>
            { title && <h2 className={css.aSection__title}>{title}</h2>}
            { children}
        </header>
    )
}

export { Section, SectionHeader }