import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"

import * as css from "@css/projects/p-category-list.module.styl"

function CategoryList({current})
{
    const q = useStaticQuery(graphql`
        query {
            list: allContentfulLog(limit: 9999) {
                group(field: category) {
                    fieldValue
                    totalCount
                }
            }
        }
    `)
    const data = q.list.group
    // data.push(q.list.group[0])
    // data.push(q.list.group[0])
    // data.push(q.list.group[0])
    return(
        <div className={css.pCategoryList}>
            <div className={css.pCategoryList__inner}>
                {/* <TransitionLink to={`/category/xaaaa/`} className={css.pCategoryList__item}>
                    テスト
                </TransitionLink> */}
                {data.map( ({fieldValue}, i) => {
                    if( fieldValue === current ){
                        return (
                            <span className={css.pCategoryList__itemCurrent} key={i}>
                                {fieldValue}
                            </span>
                        )
                    } else {
                        return (
                            <TransitionLink to={`/category/${fieldValue}/`} className={css.pCategoryList__item } key={i}>
                                {fieldValue}
                            </TransitionLink>
                        )
                    }
                })}
            </div>
        </div>
    )
}

export { CategoryList }