import React from "react"
import * as css from "@css/projects/p-tags.module.styl"

function Tags({ data }) {
    return (
        <div className={css.pTags}>
            {data.map((val, i) => {
                return <span key={i}>{val}</span>
            })}
        </div>
    )
}
export default Tags