import React from "react"
import * as css from "@css/atoms/a-spacer.module.styl"

function Header()
{
    return (
        <div className={css.aSpacerHeader}/>
    )
}

export { Header }