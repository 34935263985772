import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {TransitionLink} from "@atoms/Link"
import Tags from "@projects/Tags"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import * as css from "@css/projects/p-blog-cell.module.styl"

function BlogCell({ node, ...props})
{
    const q = useStaticQuery(graphql`
        query {
            default_thumbnail : file(relativePath: { eq: "img_default.png" }) {
                childImageSharp {
                    ...Thumbnail
                }
            }
        }
    `)
    const { title, date, category, tags, thumbnail, fields } = node
    const imgData = thumbnail ? getImage(thumbnail.gatsbyImageData) : getImage(q.default_thumbnail.childImageSharp.gatsbyImageData)
    return (
        <TransitionLink className={css.pBlogCell} to={fields.slug} {...props}>
            <div className={css.pBlogCell__inner}>
                <figure className={css.pBlogCell__thumbnail}>
                    <span className={css.pBlogCell__thumbnail__cover}></span>
                    <GatsbyImage image={imgData} alt=""/>
                </figure>
                <div className={css.pBlogCell__detail}>
                    <span className={css.pBlogCell__detail__cover}></span>
                    <p className={css.aTitle}>
                        {title}
                    </p>
                    <p className={css.aCategory}>{category}</p>
                    <div className={css.aTags}>
                        <Tags data={tags} />
                    </div>
                    <time className={css.aDate} dateTime={date}>
                        {date}
                    </time>
                </div>
            </div>
        </TransitionLink>
    )
}

export { BlogCell }