import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
import { BlogCell } from "@projects/BlogCell"
import * as css from "@css/projects/p-blog-list.module.styl"

// gsap.registerPlugin(ScrollTrigger)

export default function BlogList({data})
{
    // data.edges.push(data.edges[0])
    // data.edges.push(data.edges[0])
    // data.edges.push(data.edges[0])
    // data.edges.push(data.edges[0])
    return(
        <div className={css.pBlogList}>
            <div className={css.pBlogList__inner}>
                
                <div className={css.pBlogList__list}>
                    { data.edges.map((node,i)=>{
                        return (
                            <div className={css.pBlogList__list__item} key={i} >
                                <BlogCell {...node} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
