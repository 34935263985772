import React from "react"
import { graphql } from "gatsby"
import Seo from "@atoms/Seo"
import BlogList from "@projects/BlogList"
import { CategoryList } from "@projects/CategoryList"
import { Section, SectionHeader } from "@atoms/Section"
import  * as Spacer from "@atoms/Spacer"

// import * as css from "@css/layouts/l-index.module.styl"

const CategoryPageTemplate = ({ location, pageContext, data }) => {
	console.log(data, pageContext)
	const { category } = pageContext
	const { blog } = data
	return (
		<>
			<Seo subtitle="Category"/>
			<Spacer.Header/>
			<Section>
				<SectionHeader title={category}>
					<CategoryList current={category}/>
				</SectionHeader>
				<BlogList data={blog} />
			</Section>
		</>
	)
}


export const pageQuery = graphql`
	query PostByCategory($category: String!){
		blog: allContentfulLog(
			filter: { category: {eq: $category} }
			limit: 9999
			sort: { fields: [date], order: DESC }) {
			edges {
				node {
					...Blog
				}
			}
		}
	}
`


export default CategoryPageTemplate